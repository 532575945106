@use 'src/scss/font';

.tooltip {
  position: relative;
  width: 12rem;
  font-size: 0.9rem;
  z-index: 1056 !important;
  @include font.poppins-light;
}

.show.tooltip {
  opacity: 1;
  z-index: 1056 !important;
}

.tooltip.tooltip-top,
.tooltip.tooltip-bottom,
.tooltip.tooltip-left,
.tooltip.tooltip-right {
  z-index: 100000;
}
