.w100p {
  width: 100%;
}

@mixin w1px {
  width: 0.0625rem;
}
@mixin w2px {
  width: 0.125rem;
}
@mixin w3px {
  width: 0.1875rem;
}
@mixin w4px {
  width: 0.25rem;
}
@mixin w5px {
  width: 0.3125rem;
}
@mixin w6px {
  width: 0.375rem;
}
@mixin w7px {
  width: 0.4375rem;
}
@mixin w8px {
  width: 0.5rem;
}
@mixin w9px {
  width: 0.5625rem;
}
@mixin w10px {
  width: 0.625rem;
}
@mixin w11px {
  width: 0.6875rem;
}
@mixin w12px {
  width: 0.75rem;
}
@mixin w13px {
  width: 0.8125rem;
}
@mixin w14px {
  width: 0.875rem;
}
@mixin w15px {
  width: 0.9375rem;
}
@mixin w16px {
  width: 1rem;
}
@mixin w17px {
  width: 1.0625rem;
}
@mixin w18px {
  width: 1.125rem;
}
@mixin w19px {
  width: 1.1875rem;
}
@mixin w20px {
  width: 1.25rem;
}
@mixin w21px {
  width: 1.3125rem;
}
@mixin w22px {
  width: 1.375rem;
}
@mixin w23px {
  width: 1.4375rem;
}
@mixin w24px {
  width: 1.5rem;
}
@mixin w25px {
  width: 1.5625rem;
}
@mixin w26px {
  width: 1.625rem;
}
@mixin w27px {
  width: 1.6875rem;
}
@mixin w28px {
  width: 1.75rem;
}
@mixin w29px {
  width: 1.8125rem;
}
@mixin w30px {
  width: 1.875rem;
}
@mixin w31px {
  width: 1.9375rem;
}
@mixin w32px {
  width: 2rem;
}
@mixin w33px {
  width: 2.0625rem;
}
@mixin w34px {
  width: 2.125rem;
}
@mixin w35px {
  width: 2.1875rem;
}
@mixin w36px {
  width: 2.25rem;
}
@mixin w37px {
  width: 2.3125rem;
}
@mixin w38px {
  width: 2.375rem;
}
@mixin w39px {
  width: 2.4375rem;
}
@mixin w40px {
  width: 2.5rem;
}
@mixin w41px {
  width: 2.5625rem;
}
@mixin w42px {
  width: 2.625rem;
}
@mixin w43px {
  width: 2.6875rem;
}
@mixin w44px {
  width: 2.75rem;
}
@mixin w45px {
  width: 2.8125rem;
}
@mixin w46px {
  width: 2.875rem;
}
@mixin w47px {
  width: 2.9375rem;
}
@mixin w48px {
  width: 3rem;
}
@mixin w49px {
  width: 3.0625rem;
}
@mixin w50px {
  width: 3.125rem;
}
@mixin w51px {
  width: 3.1875rem;
}
@mixin w52px {
  width: 3.25rem;
}
@mixin w53px {
  width: 3.3125rem;
}
@mixin w54px {
  width: 3.375rem;
}
@mixin w55px {
  width: 3.4375rem;
}
@mixin w56px {
  width: 3.5rem;
}
@mixin w57px {
  width: 3.5625rem;
}
@mixin w58px {
  width: 3.625rem;
}
@mixin w59px {
  width: 3.6875rem;
}
@mixin w60px {
  width: 3.75rem;
}
@mixin w61px {
  width: 3.8125rem;
}
@mixin w62px {
  width: 3.875rem;
}
@mixin w63px {
  width: 3.9375rem;
}
@mixin w64px {
  width: 4rem;
}
@mixin w65px {
  width: 4.0625rem;
}
@mixin w66px {
  width: 4.125rem;
}
@mixin w67px {
  width: 4.1875rem;
}
@mixin w68px {
  width: 4.25rem;
}
@mixin w69px {
  width: 4.3125rem;
}
@mixin w70px {
  width: 4.375rem;
}
@mixin w71px {
  width: 4.4375rem;
}
@mixin w72px {
  width: 4.5rem;
}
@mixin w73px {
  width: 4.5625rem;
}
@mixin w74px {
  width: 4.625rem;
}
@mixin w75px {
  width: 4.6875rem;
}
@mixin w76px {
  width: 4.75rem;
}
@mixin w77px {
  width: 4.8125rem;
}
@mixin w78px {
  width: 4.875rem;
}
@mixin w79px {
  width: 4.9375rem;
}
@mixin w80px {
  width: 5rem;
}
@mixin w81px {
  width: 5.0625rem;
}
@mixin w82px {
  width: 5.125rem;
}
@mixin w83px {
  width: 5.1875rem;
}
@mixin w84px {
  width: 5.25rem;
}
@mixin w85px {
  width: 5.3125rem;
}
@mixin w86px {
  width: 5.375rem;
}
@mixin w87px {
  width: 5.4375rem;
}
@mixin w88px {
  width: 5.5rem;
}
@mixin w89px {
  width: 5.5625rem;
}
@mixin w90px {
  width: 5.625rem;
}
@mixin w91px {
  width: 5.6875rem;
}
@mixin w92px {
  width: 5.75rem;
}
@mixin w93px {
  width: 5.8125rem;
}
@mixin w94px {
  width: 5.875rem;
}
@mixin w95px {
  width: 5.9375rem;
}
@mixin w96px {
  width: 6rem;
}
@mixin w97px {
  width: 6.0625rem;
}
@mixin w98px {
  width: 6.125rem;
}
@mixin w99px {
  width: 6.1875rem;
}
@mixin w100px {
  width: 6.25rem;
}
