@use 'font-family';
// If you want to override variables do it here
@import 'variables';

// bootstrap
@import 'bootstrap/dist/css/bootstrap.min.css';

// Import Font
@import 'font';

// Import toast style
@import 'ngx-toastr/toastr';

// If you want to add something do it here - must be below all other imported styles
@import 'buttons';
@import 'stylesheet';
@import 'material';
@import 'toastr';
@import 'animations';
@import 'display';
@import 'flex';
@import 'float';
@import 'height';
@import 'margins';
@import 'padding';
@import 'position';
@import 'tooltip';
@import 'width';
@import 'reactive-form';
@import 'search-bar';
@import 'pagination';

// _reboot.scss is imported by bootstrap and sets hr opacity to 0.25, it's screwing up our styles
hr {
  opacity: unset;
}

// Required for loading in body div
.main {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

app-dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-body {
  background-color: $bs-background-color;
}

.app-body .main {
  flex: 1;
  min-width: 0;
  min-height: 100vh;
}

body {
  -ms-overflow-style: none;
  overflow-x: hidden;
}

hr {
  border-top: 1px solid $bs-default-gray-color;
}

.bs-border-bottom {
  border-bottom: 1px solid $bs-default-gray-color;
}

.main-content {
  padding-left: 3rem;
  padding-right: 3rem;
  overflow: scroll;
  height: 100%;
}

.header-padding {
  padding-top: 1.5rem;
}

.padding-for-support-button {
  padding-bottom: 5rem;
}

.lh-80p {
  line-height: 80%;
}

.lh-80p {
  line-height: 80%;
}

.full-centered-text {
  text-align: center;
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.modal-content {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
  border: unset;
  overflow: hidden;
}

.blurred-background {
  backdrop-filter: blur(2px);
}

// Text wrapping

.ellipsis-nowrap-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.live-view-modal-landscape .modal-xl {
  max-width: 95vw;
  max-height: 90vh;
}

.live-view-modal-portrait .modal-xl {
  max-width: 65vw;
  max-height: 95vh;
}

.live-view-modal-print-card .modal-xl {
  max-width: 95vw;
  max-height: 90vh;
}

.edit-variant-modal .modal-xl {
  width: 75rem;
}

.cdk-overlay-container {
  z-index: 1052;
}

.form-input-title {
  display: inline-block;
  width: fit-content;
  font-size: 1rem;
  line-height: 3rem;
  text-align: left;
  margin: 0;
  @extend .bs-bold;
}
