@use 'variables' as v;
@use 'font' as f;

// Nav Bar

.sidebar {
  width: v.$bs-sidebar-width !important;
}

.app-body .sidebar-show ~ .main {
  margin-left: v.$bs-sidebar-width !important;
}

.app-body .sidebar-hide ~ .main {
  margin-left: 0 !important;
}

.style-nav {
  background-color: v.$nav-background-color;
  padding: 2rem;
}

.nav-ul {
  padding: 0;
}

.bs-admin-pill {
  font-size: 0.625rem;
  background-color: v.$pill-active-color;
  color: v.$bs-white-color;
  padding: 0.25rem 0.5rem;
  border-radius: 0.6875rem;
  border: 0;
  text-transform: uppercase;
  height: fit-content;
  @include f.bs-medium;
}

.bs-admin-pill:focus {
  outline: none;
}

/* Text_Field_Start ----------------------------------------------- */
$bs-text-field-height: 2.5rem;

.bs-textfield-cover {
  position: absolute;
  height: $bs-text-field-height;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  border: 1px solid v.$bs-dark-gray-color;
  border-radius: 0.625rem;
  background-color: transparent;
}

.bs-text-field-cover-disabled {
  cursor: no-drop;
}

//noinspection ALL
.bs-textfield {
  color: v.$bs-primary-text-color;
  height: $bs-text-field-height;
  width: -webkit-fill-available;
  border-radius: 0.625rem;
  border: 0;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: left;
  background-color: v.$textfield-fill-color;
  border: 1px solid v.$textfield-fill-color;
  padding: 0.8125rem 0.5rem;
  @include f.bs-regular;
}

.bs-textfield:read-only {
  outline: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: v.$bs-default-gray-color;
}

.bs-textfield:read-only:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: v.$bs-default-gray-color;
}

.bs-textfield:focus {
  color: v.$bs-primary-text-color;
  outline: none;
  border: 1px solid v.$textfield-focus-border-color;
  background-color: v.$textfield-focus-fill-color;
  box-shadow: 0 0 1px 1px v.$bs-extra-light-grey-color;
  @include f.bs-medium;
}

/* ------------------------------------------------- Text_Field_End */

// I named things according to how they were labelled in xd.adobe by Conor
/* Text_Start ----------------------------------------------- */
.bs-headline {
  @include f.bs-bold;
  @include f.bs-zero-letter-spacing;
  font-size: 2rem;
  color: v.$bs-black-color;
  text-align: left;
  opacity: 1;
}

.bs-section-title {
  @include f.bs-bold;
  @include f.bs-zero-letter-spacing;
  font-size: 1.5rem;
  color: v.$bs-black-color;
  text-align: left;
  opacity: 1;
}

.bs-section-title.inline {
  display: inline-block;
}

.bs-section-subtitle {
  @include f.bs-bold;
  @include f.bs-zero-letter-spacing;
  color: v.$bs-black-color;
  font-size: 1rem;
  text-align: left;
  opacity: 1;
}

.bs-section-subtext {
  @include f.bs-regular;
  @include f.bs-zero-letter-spacing;
  color: v.$bs-extra-dark-gray-color;
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: left;
  opacity: 1;
}

.bs-section-desc {
  @include f.bs-regular;
  @include f.bs-zero-letter-spacing;
  color: v.$bs-black-color;
  @include f.f14px;
  text-align: left;
  opacity: 1;
}

.bs-modal-section-header-text {
  @include f.bs-bold;
  @include f.bs-zero-letter-spacing;
  color: v.$bs-black-color;
  font-size: 1rem;
  text-align: left;
  opacity: 1;
}

.bs-error-text {
  @include f.bs-italic;
  @include f.bs-zero-letter-spacing;
  color: v.$bs-red-color;
  font-size: 0.625rem;
  text-align: left;
  opacity: 1;
}

h3 {
  @include f.bs-bold;
  @include f.bs-zero-letter-spacing;
  color: v.$bs-black-color;
  text-align: left;
  font-size: 1.125rem;
  opacity: 1;
}

body {
  @include f.bs-regular;
  @include f.bs-zero-letter-spacing;
  color: v.$bs-black-color;
  text-align: left;
  font-size: 0.875rem;
  opacity: 1;
}

.dark-grey-text {
  color: v.$bs-secondary-dark-grey;
}

/* ------------------------------------------------- Text_End */

/* Buttons_Start -------------------------------------------- */
.bs-button {
  padding: 0.75rem 1rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 0.625rem;
  line-height: 1.125rem;
  opacity: 1;
  height: 2.75rem;
  font-size: 0.875rem;
  text-align: center;
  vertical-align: middle;
  transition: background 0.8s;
  cursor: pointer;
  @include f.bs-zero-letter-spacing;
  @include f.bs-semi-bold;
  @extend .ripple;
}

.bs-button img {
  margin-right: 0.5rem;
  height: 100%;
}

.bs-button:focus {
  outline: none;
}

.bs-button:active {
  background-color: v.$bs-light-grey-color;
  background-size: 100%;
  transition: background 0s;
}

.bs-button:disabled {
  cursor: not-allowed;
}

.preferred-button {
  background-color: v.$bs-primary-blue-color;
  color: v.$button-white-text-color;
}

.inverse-preferred-button {
  color: v.$bs-primary-blue-color;
  background-color: v.$button-white-text-color;
}

.no-left-margin {
  margin-left: 0;
}

.no-right-margin {
  margin-right: 0;
}

.icon-button-icon {
  margin-right: 0.625rem;
  height: 100%;
}

.rounded-icon-button {
  height: 2rem;
  width: 2rem;
  padding: 0.4rem;
  border-radius: 50%;
}

.edit-label-icon-button {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.4rem;
  border-radius: 50%;
  background-color: v.$bs-white-color;
}

.rounded-icon-button .icon-button-icon {
  margin: 0;
}

.preferred-button:hover {
  background-color: v.$button-preferred-hover-color;
}

.preferred-button.disabled {
  background-color: v.$bs-light-grey-color;
}

.neutral-button {
  background-color: v.$button-neutral-color;
  color: v.$button-black-text-color;
}

.neutral-button:hover {
  background-color: v.$button-neutral-hover-color;
}

.neutral-button.disabled {
  background-color: v.$bs-default-gray-color;
  opacity: 0.5;
}

.dark-neutral-button {
  background-color: v.$button-dark-neutral-color;
  color: v.$button-white-text-color;
}

.dark-neutral-button:hover {
  background-color: v.$button-dark-neutral-hover-color;
}

.dark-neutral-button.disabled {
  background-color: v.$bs-default-gray-color;
}

.outlined-button {
  background-color: v.$bs-white-color;
  color: v.$button-black-text-color;
  @extend .bordered-button;
}

.outlined-button:hover {
  background-color: v.$button-outlined-hover-color;
}

.outlined-button.disabled {
  background-color: v.$bs-light-grey-color;
  border: none;
}

.destructive-button {
  background-color: v.$bs-white-color;
  color: v.$bs-red-color;
  border: 0.124rem solid v.$bs-red-color;
}

.destructive-button:hover {
  background-color: v.$bs-red-color;
  color: v.$button-white-text-color;
}

.destructive-button.disabled {
  color: v.$button-black-text-color;
  border: none;
  background-color: v.$bs-default-gray-color;
  opacity: 0.5;
}

.destructive-button.disabled:hover {
  color: unset;
}

.text-button {
  background-color: v.$button-text-color;
  color: v.$button-black-text-color;
}

.text-button:hover {
  background-color: v.$button-text-hover-color;
}

.back-button {
  padding: 0.75rem 1rem 0.75rem 0;
  background-color: v.$button-text-color;
  color: v.$button-black-text-color;
  opacity: 0.5;
}

.back-button img {
  margin-right: 0.25rem;
}

.back-button:hover {
  opacity: 1;
  background-color: v.$button-text-color;
}

.text-link-helper {
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: left;
  color: v.$bs-primary-blue-color;
  @include f.bs-medium;
}

.inline-text-link {
  display: inline-block;
  margin: 0 0.5rem;
}

.text-link {
  color: v.$bs-primary-blue-color;
  @include f.poppins-medium;
}

.text-link.disabled {
  color: v.$bs-light-grey-color;
  pointer-events: none;
}

.text-link.disabled:hover {
  text-decoration: none;
  cursor: not-allowed;
}

.text-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-link.destructive-link {
  color: v.$bs-red-color;
}

.text-link.dark-grey-link {
  color: v.$bs-dark-gray-color;
}

.text-link.destructive-link.disabled {
  color: v.$bs-light-red-color;
  cursor: not-allowed;
}

.bordered-button {
  border: 0.124rem solid v.$button-border-color;
}

.full-width {
  width: 100%;
}

.in-button-loading {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 1.5rem;
  height: 100%;
}

.in-button-loading app-loading .loading-container {
  background-color: transparent !important;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.2s, opacity 0.5s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}

/* ---------------------------------------------- Buttons_End */

/* Labels_Start -------------------------------------------- */
.bs-label {
  display: flex;
  width: fit-content;
  white-space: nowrap;
  padding: 0.125rem 0.4rem;
  border: none;
  border-radius: 0.3rem;
  line-height: 1.125rem;
  opacity: 1;
  height: 1.25rem;
  font-size: 0.625rem;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  align-items: center;
  @include f.bs-semi-bold;
}

.radio-product-label {
  flex: 1;
}

.preferred-label {
  background-color: v.$bs-primary-blue-color;
  color: v.$bs-white-color;
}

.positive-label {
  background-color: v.$label-positive-color;
  color: v.$label-positive-text-color;
}

.neutral-label {
  background-color: v.$label-neutral-color;
  color: v.$label-neutral-text-color;
}

.negative-label {
  background-color: v.$label-negative-color;
  color: v.$label-negative-text-color;
}

/* ---------------------------------------------- Labels_End */

/* Toggle_Switch_Start ------------------------------------- */
.bs-switch-container {
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: left;
  color: v.$bs-primary-text-color;
  @include f.bs-medium;

  img {
    height: 1rem;
    width: 1rem;
    margin-left: 1.5rem;
    opacity: 0.5;
  }

  label {
    margin-left: 1rem;
  }
}

/* The switch - the box around the slider */

label.bs-switch {
  margin: 0 0 0 1rem;
}

.bs-switch {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 2.875rem;
  height: 1.5rem;
  margin-left: 1.125rem;
}

/* Hide default HTML checkbox */
.bs-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.bs-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: v.$switch-base-color;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.bs-slider:hover {
  background-color: v.$switch-hover-color;
}

.bs-slider:before {
  position: absolute;
  content: '';
  height: 1.25rem;
  width: 1.25rem;
  top: 0.1rem;
  left: 0.15rem;
  background-color: v.$bs-white-color;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .bs-slider {
  background-color: v.$switch-activated-color;
}

input:focus + .bs-slider {
  outline: none;
}

input:checked + .bs-slider:before {
  -webkit-transform: translateX(1.375rem);
  -ms-transform: translateX(1.375rem);
  transform: translateX(1.375rem);
}

/* --------------------------------------- Toggle_Switch_End */

/* Radio_Start --------------------------------------------- */
.bs-radio-form {
  vertical-align: middle;
}

.bs-radio-label {
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: left;
  padding-left: 0.375rem;
  vertical-align: middle;
  margin-bottom: 0;
  @include f.bs-medium;
}

.bs-radio {
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: middle;
}

.bs-radio-option {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

.bs-radio-option.disabled {
  opacity: 0.4;
}

.bs-radio-option-inline {
  display: inline-block;
  padding: 0 1rem;
}

.bs-radio-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.bs-custom-radio {
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: middle;
  background-color: v.$radio-base-color;
  border-radius: 50%;
  flex-shrink: 0;
}

.bs-radio-option.disabled input {
  cursor: not-allowed;
}

.bs-radio-option.disabled:hover .bs-custom-radio {
  background: v.$radio-base-color;
}

.bs-radio-option:hover .bs-custom-radio {
  background-color: v.$radio-hover-color;
}

.bs-radio-option input:checked ~ .bs-custom-radio {
  background-color: v.$radio-activated-color;
}

.bs-custom-radio:after {
  content: '';
  position: absolute;
  display: none;
}

.bs-radio-option input:checked ~ .bs-custom-radio:after {
  display: block;
}

.bs-radio-option .bs-custom-radio:after {
  position: relative;
  top: 0.625rem - calc(0.375rem / 2);
  left: 0.625rem - calc(0.375rem / 2);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: v.$bs-white-color;
}

/* ----------------------------------------------- Radio_End */

/* Toggle_Start -------------------------------------------- */
.bs-toggle {
  border-radius: 2px;
  background-color: v.$toggle-base-color;
  height: 2rem;
  width: 2rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.5625rem 0;
  @include f.bs-medium;
}

.bs-toggle:hover {
  background-color: v.$toggle-hover-color;
}

.bs-toggle.active {
  background-color: v.$toggle-activated-color;
  color: v.$bs-white-color;
}

/* ---------------------------------------------- Toggle_End */

/* Cards_Start --------------------------------------------- */

.bs-card {
  width: 14rem;
  height: 10rem;
  min-width: 14rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  opacity: 1;
  background-size: cover;
  background-position: top;
  cursor: pointer;
  margin: 0;
  @extend .bs-card-radius-top;
  @extend .bs-card-radius-bottom;
}

.card.bs-card:hover .bs-card-label {
  background-color: v.$bs-hover-gray-color;
  opacity: 1;
  height: 5rem;
  transition: 0.25s ease-in-out;
}

.bs-card-label {
  height: 4.5rem;
  background: v.$card-banner-color 0 0 no-repeat padding-box;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: auto;
  @extend .bs-card-radius-bottom;
}

.bs-card-radius-top {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.bs-card-radius-bottom {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.bs-card-label-title {
  @include f.bs-medium;
  @include f.bs-zero-letter-spacing;
  text-align: left;
  font-size: 0.875rem;
  color: #737680;
  opacity: 1;
}

.bs-card-label-text {
  @include f.bs-bold;
  @include f.bs-zero-letter-spacing;
  font-size: 1.3rem;
  text-align: left;
  color: v.$card-text-color;
  opacity: 1;
}

.card-deck {
  margin: 1.5rem 0 0 0;
}

.bs-card-deck {
  margin: 1.5rem 0 0 0;
  overflow-y: hidden;
  max-height: 20rem;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 0.25rem;
}

.bs-card-deck .card {
  display: inline-flex !important;
}

.bs-card-with-bottom-margin {
  margin-bottom: 1rem !important;
}

/* ----------------------------------------------- Cards_End */

/* Media_Upload_Start -------------------------------------- */
.bs-upload-area {
  border: 1px dashed v.$media-upload-border;
  border-radius: 0.625rem;
  background-color: v.$media-upload-background;
  width: 100%;

  .disabled {
    opacity: 10%;
    cursor: not-allowed;
  }
}

.bs-upload-form {
  text-align: center;
}

.bs-upload-icon {
  height: 1.5rem;
  margin: 1.5rem auto auto;
  display: block;
}

.bs-upload-text {
  display: block;
  padding-top: 1rem;
  font-size: 0.875rem;
  line-height: 1rem;
  @include f.bs-medium;
}

.bs-upload-label {
  color: v.$media-upload-blue-color;
}

.bs-upload-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.bs-upload-disable {
  color: v.$media-upload-grey-color;
}

.secondary-upload-text {
  padding-top: 0.5rem;
  color: v.$media-upload-grey-color;
  margin-bottom: 1.5rem;
}

.bs-file-input {
  display: none;
}

.bs-upload-list-container {
  width: 100%;
  padding: 1.25rem 0;
}

.bs-upload-list {
  list-style: none;
  padding: 0;
}

.bs-upload-button {
  display: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.bs-upload-button:focus {
  outline: none;
}

.bs-upload-button-icon {
  height: 1.25rem;
  background-color: transparent;
  filter: invert(49%) sepia(8%) saturate(428%) hue-rotate(189deg) brightness(92%) contrast(86%);
}

.bs-upload-preview.error .bs-remove-upload-button {
  display: none;
}

.bs-upload-preview.error .bs-refresh-upload-button {
  display: inline-block;
}

.bs-upload-preview.success .bs-remove-upload-button {
  display: inline-block;
}

.bs-upload-preview.success .bs-refresh-upload-button {
  display: none;
}

/* ---------------------------------------- Media_Upload_End */

/* Lists_Start --------------------------------------------- */
.bs-search-results {
  width: 100%;
  border-radius: 0.625rem;
  background-color: v.$list-background-color;
  border: 1px solid v.$list-border-color;
  margin: 0.625rem;
  max-height: 33vh;
}

.bs-results-container {
  overflow: scroll;
  max-height: 26vh;
  display: block;
}

.bs-search-results-header {
  padding: 1.5625rem 0 1rem 0;
  display: block;
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: left;
  border-bottom: 1px solid v.$bs-default-gray-color;
  width: calc(100% - 4rem);
  margin: 0 2rem;
  @include f.bs-medium;
}

.bs-list {
  list-style: none;
  padding: 0 1rem 0.5rem 1rem;
  margin: 0;
}

.bs-list-item-image {
  height: 3rem;
  width: 3rem;
  margin: 0 0 0 0.75rem;
  border-radius: 0.25rem;
}

.bs-list-item-image-icon {
  height: 3rem;
  width: 3rem;
  margin: 0 0 0 0.75rem;
  border-radius: 0.25rem;
  background-color: v.$bs-default-gray-color;
  padding: 0.75rem;
}

.bs-list-item-text-container {
  padding: 0 1rem;
  display: inline-block;
  width: calc(100% - 4rem);
  vertical-align: middle;
}

.bs-list-item-text {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  @include f.bs-bold;
}

.bs-list-item-subtext {
  font-size: 0.625rem;
  line-height: 0.75rem;
  display: flex;
  @include f.bs-medium;
}

.bs-list-item {
  padding: 0.75rem 0;
}

.bs-list-item:hover {
  background-color: v.$list-hover-color;
  cursor: pointer;
  border-radius: 0.25rem;
}

.bs-list-item.inactive:hover {
  background-color: v.$list-background-color;
}

.bs-list-item.active {
  background-color: v.$list-active-color;
  cursor: text !important;
}

/* ----------------------------------------------- Lists_End */

/* Image_Start --------------------------------------------- */
.bs-profile-image {
  display: inline-block;
  border-radius: 50%;
  vertical-align: bottom;
  height: 5.625rem; // 90px;
  width: 5.625rem; // 90px;
  background-color: v.$bs-default-gray-color;
  cursor: pointer;
}

.bs-profile-image-padding {
  padding: 1.75rem;
}

/* ----------------------------------------------- Image_End */

/* Modal_Start --------------------------------------------- */

.modal-bs-xl {
  max-width: 65.25rem;
}

.modal-bs-xxl {
  max-width: 80.25rem;
}

.sticky-header-container {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  padding: 1.5rem 1.5rem 0 1.5rem;
  z-index: 10;
}

.sticky-body-container {
  overflow-y: auto;
  padding: 0;
  padding-inline: 0.2rem;
  border-left: 1.5rem solid v.$bs-white-color;
  border-right: 1.5rem solid v.$bs-white-color;
  z-index: 0;

  &.max-out {
    height: 100%;
  }
}

.sticky-footer-container {
  background: linear-gradient(
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.9),
    v.$bs-white-color,
    v.$bs-white-color
  );
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 1.5rem 1.5rem 1.5rem;
  z-index: 10;
}

.sticky-footer-container.no-right-padding {
  padding: 0 0 1.5rem 1.5rem;
}

.sticky-footer-container.modal-footer-flex-end {
  padding-top: 3rem;
}

.modal-form-negative-padding {
  margin: 0 -1.5rem;
}

.modal-body {
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  padding: 1.5rem;

  &.max-out {
    height: calc(100vh - 4rem);
  }
}

.relative {
  position: relative;
}

.modal-body.sticky {
  padding: 0;
}

.modal-tab-component {
  overflow-y: auto;
}

.modal-header {
  display: block;
  padding: 0 0 1.25rem 0;
  border-bottom: 1px solid v.$bs-default-gray-color;

  &.no-bottom-border {
    border-bottom: unset;
  }
}

.white-background {
  background-color: v.$bs-white-color;
}

.modal-title {
  display: block;
  font-size: 1.5rem;
  color: v.$bs-primary-text-color;
  @include f.bs-bold;
}

.modal-subtitle {
  display: block;
  font-size: 1.125rem;
  line-height: 2rem;
  color: v.$bs-primary-text-color;
  @include f.bs-medium;
}

.modal-sub-subtitle {
  font-size: 0.875rem;
  text-align: left;
  color: v.$bs-primary-text-color;
  @include f.bs-medium;
}

.modal-sub-subtitle-text {
  display: inline-block;
  width: 50%;
  color: v.$bs-extra-dark-gray-color;
  @include f.bs-medium;
}

.modal-sub-subtitle-text span {
  display: inline-block;
  color: v.$bs-primary-text-color;
  @include f.bs-semi-bold;
}

.inline-subtitle {
  display: inline-block;
  min-width: 50%;
}

.modal-subtitle-action {
  right: 1rem;
  top: 1.5rem;
  position: absolute;
  text-align: right;
}

.footer-button {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 1rem 0;
  float: right;
}

.modal-footer-flex-end {
  display: flex;
  justify-content: flex-end;
}

.modal-divider {
  width: 100%;
  border-bottom: 1px solid v.$bs-hover-gray-color;
  margin: 0.5rem 0;
  padding-top: 0.5rem;
}

.modal-search-results-container {
  height: 22rem;
}

/* ----------------------------------------------- Modal_End */

/* Drop_Down_Menu_Start ------------------------------------ */
.bs-dropdown-menu {
  border-radius: 0.625rem;
  width: fit-content;
  overflow: visible;
  border-color: v.$bs-hover-gray-color;
}

.bs-dropdown-menu-header {
  padding-top: 1rem;
  border-bottom: 1px solid v.$bs-black-color;
  padding-bottom: 3px;
  font-size: 16px;
  margin-left: 1rem;
  margin-right: 1rem;
  @include f.bs-bold;
  @extend .bs-item-sizing;
}

.bs-dropdown-menu-item {
  border: none;
  width: 100%;
  min-width: 200px;
  max-width: 320px;
  background-color: v.$bs-white-color;
  text-align: left;
  border-radius: 0.625rem;
  padding: 0.625rem 1rem;
  @include f.bs-regular;
  @extend .bs-item-sizing;
}

.bs-item-sizing {
  min-width: 200px;
  max-width: 400px;
}

.bs-dropdown-menu-item:hover {
  background-color: v.$bs-off-white-color;
}

.bs-dropdown-menu-item:focus {
  outline: none;
  background-color: v.$bs-hover-gray-color;
}

.bs-dropdown-menu-remove {
  color: v.$bs-red-color;
}

/* -------------------------------------- Drop_Down_Menu_End */

/* Drop_Down_Start ----------------------------------------- */
.bs-dropdown {
  padding: 0 0.75rem 0 0.875rem;
  width: 100%;
  height: 2.5rem;
  border-radius: 0.625rem;
  background-color: v.$dropdown-base-color;
  color: v.$dropdown-text-color;
  font-size: 0.875rem;
  line-height: 1.125rem;
  border: none;
  @include f.bs-regular;
  // Hide the default chevron
  -webkit-appearance: none;
  -moz-appearance: none;
  // Add the custom chevron
  background-image: url(/assets/icons/light/solid/drop-down.svg);
  background-position-x: calc(100% - 0.7rem);
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 1.5rem;
}

.bs-dropdown:focus {
  outline: none;
}

.bs-dropdown:enabled:hover {
  background-color: v.$dropdown-hover-color;
  border: 1px solid v.$dropdown-hover-border-color;
  color: v.$dropdown-hover-text-color;
  @include f.bs-regular;
}

.bs-dropdown:disabled,
.bs-textfield:disabled,
.bs-switch-input:disabled,
.bs-slider.disabled {
  opacity: 0.5;
}

.bs-dropdown:disabled:hover,
.bs-textfield:disabled:hover,
.bs-switch-input:disabled:hover,
.bs-slider.disabled:hover {
  cursor: not-allowed;
}

.bs-dropdown-label {
  font-size: 0.8rem;
  line-height: 1rem;
  color: v.$bs-black-color;
  @include f.bs-medium;
}

/* ------------------------------------------- Drop_Down_End */

/* No_Results_Start ----------------------------------------- */

.no-results-container {
  margin: 6rem auto auto auto;
  width: 40%;
  height: 18rem;
  display: block;
}

.no-results-title {
  font-size: 1.5rem;
  line-height: 3rem;
  color: v.$bs-primary-text-color;
  @include f.bs-bold;
}

.no-results-body {
  font-size: 1rem;
  line-height: 1.125rem;
  color: v.$bs-extra-dark-gray-color;
  @include f.bs-regular;
}

.no-results-button {
  margin: 1.5rem 0 0 0;
}

/* ------------------------------------------- No_Results_End */

/* Edit_Section_Start ----------------------------------------- */

.bs-edit-section-title {
  font-size: 1.125rem;
  color: v.$bs-primary-text-color;
  @include f.bs-bold;
}

.bs-edit-section-description {
  @include f.show-new-lines;
  font-size: 0.75rem;
  color: v.$bs-primary-text-color;
  @include f.bs-regular;
}

.bs-edit-section-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.bs-edit-section-button-container .bs-button {
  margin: 0 0.5rem 0 0;
}

.bs-section-container {
  position: relative;
}

.bs-section-header {
  display: block;
  width: 100%;
}

.bs-section-body {
  display: block;
  width: 100%;
}

.bs-section-header-button-container {
  display: inline-block;
  float: right;
}

.bs-section-list-title {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: v.$bs-primary-text-color;
  @include f.bs-bold;
}

/* ------------------------------------------- Edit_Section_End */

/* Tooltip_Start ----------------------------------------- */

.inline-tooltip {
  height: 0.75rem;
  width: 0.75rem;
}

.inline-tooltip:focus {
  outline: none;
}

.datatable-action-button img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.inline-tooltip img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.inline-tooltip {
  outline: none;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 0.25rem;
}

.inline-tooltip:hover img {
  opacity: 1;
}

/* ------------------------------------------- Tooltip_End */

/* Warning_Start ----------------------------------------- */

.warning-message {
  margin: 0.5rem 1.5rem;
  padding: 0.75rem 1rem;
  background-color: v.$bs-yellow-color-80;
  border-radius: 0.5rem;
  text-align: left;
  color: v.$bs-primary-text-color;
  @include f.bs-semi-bold;
}

.warning-message .tooltip-container {
  float: right;
}

/* ------------------------------------------- Warning_End */

/* Popper_Start --------------------------------------------- */

@mixin custom-popper-content {
  border: none !important;
  border-radius: 0.625rem !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.custom-popper-color-picker {
  @include custom-popper-content;
  padding: 0 !important;
}

.company-color-picker-popper {
  @include custom-popper-content;
}

.badge-picker-popper {
  @include custom-popper-content;
  padding: 1rem;
}

.product-info-popper {
  @include custom-popper-content;
  overflow: auto;
  padding: 0 !important;

  [data-popper-arrow] {
    display: none !important;
  }
}

.options-picker-popper {
  @include custom-popper-content;
  padding: 0 !important;
  overflow: auto;
  max-height: 32rem;
}

.label-picker-popper {
  @include custom-popper-content;
  padding: 1rem;
}

.look-ahead-popper {
  @include custom-popper-content;
  padding: 0.5rem 0 !important;
}

.location-list-popper {
  @include custom-popper-content;
  padding: 0 !important;
}

/* ----------------------------------------------- Popper_End */

/* Container_Start ------------------------------------------ */

.bs-narrow-container {
  max-width: 546px;
  margin-left: auto;
  margin-right: auto;
}

/* -------------------------------------------- Container_End */
