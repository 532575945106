.float-left {
  float: left;
}
.float-right {
  float: right;
}
.float-down {
  float: bottom;
}
.float-up {
  float: top;
}
