.no-margin {
  margin: 0;
}
.auto-margin {
  margin: auto;
}

// Top
.mt-none {
  margin-top: 0rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1px {
  margin-top: 0.0625rem;
}
.mt-2px {
  margin-top: 0.125rem;
}
.mt-3px {
  margin-top: 0.1875rem;
}
.mt-4px {
  margin-top: 0.25rem;
}
.mt-5px {
  margin-top: 0.3125rem;
}
.mt-6px {
  margin-top: 0.375rem;
}
.mt-7px {
  margin-top: 0.4375rem;
}
.mt-8px {
  margin-top: 0.5rem;
}
.mt-9px {
  margin-top: 0.5625rem;
}
.mt-10px {
  margin-top: 0.625rem;
}
.mt-11px {
  margin-top: 0.6875rem;
}
.mt-12px {
  margin-top: 0.75rem;
}
.mt-13px {
  margin-top: 0.8125rem;
}
.mt-14px {
  margin-top: 0.875rem;
}
.mt-15px {
  margin-top: 0.9375rem;
}
.mt-16px {
  margin-top: 1rem;
}
.mt-17px {
  margin-top: 1.0625rem;
}
.mt-18px {
  margin-top: 1.125rem;
}
.mt-19px {
  margin-top: 1.1875rem;
}
.mt-20px {
  margin-top: 1.25rem;
}
.mt-21px {
  margin-top: 1.3125rem;
}
.mt-22px {
  margin-top: 1.375rem;
}
.mt-23px {
  margin-top: 1.4375rem;
}
.mt-24px {
  margin-top: 1.5rem;
}
.mt-25px {
  margin-top: 1.5625rem;
}
.mt-26px {
  margin-top: 1.625rem;
}
.mt-27px {
  margin-top: 1.6875rem;
}
.mt-28px {
  margin-top: 1.75rem;
}
.mt-29px {
  margin-top: 1.8125rem;
}
.mt-30px {
  margin-top: 1.875rem;
}
.mt-31px {
  margin-top: 1.9375rem;
}
.mt-32px {
  margin-top: 2rem;
}
.mt-33px {
  margin-top: 2.0625rem;
}
.mt-34px {
  margin-top: 2.125rem;
}
.mt-35px {
  margin-top: 2.1875rem;
}
.mt-36px {
  margin-top: 2.25rem;
}
.mt-37px {
  margin-top: 2.3125rem;
}
.mt-38px {
  margin-top: 2.375rem;
}
.mt-39px {
  margin-top: 2.4375rem;
}
.mt-40px {
  margin-top: 2.5rem;
}
.mt-41px {
  margin-top: 2.5625rem;
}
.mt-42px {
  margin-top: 2.625rem;
}
.mt-43px {
  margin-top: 2.6875rem;
}
.mt-44px {
  margin-top: 2.75rem;
}
.mt-45px {
  margin-top: 2.8125rem;
}
.mt-46px {
  margin-top: 2.875rem;
}
.mt-47px {
  margin-top: 2.9375rem;
}
.mt-48px {
  margin-top: 3rem;
}
.mt-49px {
  margin-top: 3.0625rem;
}
.mt-50px {
  margin-top: 3.125rem;
}
.mt-51px {
  margin-top: 3.1875rem;
}
.mt-52px {
  margin-top: 3.25rem;
}
.mt-53px {
  margin-top: 3.3125rem;
}
.mt-54px {
  margin-top: 3.375rem;
}
.mt-55px {
  margin-top: 3.4375rem;
}
.mt-56px {
  margin-top: 3.5rem;
}
.mt-57px {
  margin-top: 3.5625rem;
}
.mt-58px {
  margin-top: 3.625rem;
}
.mt-59px {
  margin-top: 3.6875rem;
}
.mt-60px {
  margin-top: 3.75rem;
}
.mt-61px {
  margin-top: 3.8125rem;
}
.mt-62px {
  margin-top: 3.875rem;
}
.mt-63px {
  margin-top: 3.9375rem;
}
.mt-64px {
  margin-top: 4rem;
}
.mt-65px {
  margin-top: 4.0625rem;
}
.mt-66px {
  margin-top: 4.125rem;
}
.mt-67px {
  margin-top: 4.1875rem;
}
.mt-68px {
  margin-top: 4.25rem;
}
.mt-69px {
  margin-top: 4.3125rem;
}
.mt-70px {
  margin-top: 4.375rem;
}
.mt-71px {
  margin-top: 4.4375rem;
}
.mt-72px {
  margin-top: 4.5rem;
}
.mt-73px {
  margin-top: 4.5625rem;
}
.mt-74px {
  margin-top: 4.625rem;
}
.mt-75px {
  margin-top: 4.6875rem;
}
.mt-76px {
  margin-top: 4.75rem;
}
.mt-77px {
  margin-top: 4.8125rem;
}
.mt-78px {
  margin-top: 4.875rem;
}
.mt-79px {
  margin-top: 4.9375rem;
}
.mt-80px {
  margin-top: 5rem;
}
.mt-81px {
  margin-top: 5.0625rem;
}
.mt-82px {
  margin-top: 5.125rem;
}
.mt-83px {
  margin-top: 5.1875rem;
}
.mt-84px {
  margin-top: 5.25rem;
}
.mt-85px {
  margin-top: 5.3125rem;
}
.mt-86px {
  margin-top: 5.375rem;
}
.mt-87px {
  margin-top: 5.4375rem;
}
.mt-88px {
  margin-top: 5.5rem;
}
.mt-89px {
  margin-top: 5.5625rem;
}
.mt-90px {
  margin-top: 5.625rem;
}
.mt-91px {
  margin-top: 5.6875rem;
}
.mt-92px {
  margin-top: 5.75rem;
}
.mt-93px {
  margin-top: 5.8125rem;
}
.mt-94px {
  margin-top: 5.875rem;
}
.mt-95px {
  margin-top: 5.9375rem;
}
.mt-96px {
  margin-top: 6rem;
}
.mt-97px {
  margin-top: 6.0625rem;
}
.mt-98px {
  margin-top: 6.125rem;
}
.mt-99px {
  margin-top: 6.1875rem;
}
.mt-100px {
  margin-top: 6.25rem;
}

// left
.ml-none {
  margin-left: 0rem;
}
.ml-0 {
  margin-left: 0;
}
.ml-1px {
  margin-left: 0.0625rem;
}
.ml-2px {
  margin-left: 0.125rem;
}
.ml-3px {
  margin-left: 0.1875rem;
}
.ml-4px {
  margin-left: 0.25rem;
}
.ml-5px {
  margin-left: 0.3125rem;
}
.ml-6px {
  margin-left: 0.375rem;
}
.ml-7px {
  margin-left: 0.4375rem;
}
.ml-8px {
  margin-left: 0.5rem;
}
.ml-9px {
  margin-left: 0.5625rem;
}
.ml-10px {
  margin-left: 0.625rem;
}
.ml-11px {
  margin-left: 0.6875rem;
}
.ml-12px {
  margin-left: 0.75rem;
}
.ml-13px {
  margin-left: 0.8125rem;
}
.ml-14px {
  margin-left: 0.875rem;
}
.ml-15px {
  margin-left: 0.9375rem;
}
.ml-16px {
  margin-left: 1rem;
}
.ml-16px-force {
  margin-left: 1rem !important;
}
.ml-17px {
  margin-left: 1.0625rem;
}
.ml-18px {
  margin-left: 1.125rem;
}
.ml-19px {
  margin-left: 1.1875rem;
}
.ml-20px {
  margin-left: 1.25rem;
}
.ml-21px {
  margin-left: 1.3125rem;
}
.ml-22px {
  margin-left: 1.375rem;
}
.ml-23px {
  margin-left: 1.4375rem;
}
.ml-24px {
  margin-left: 1.5rem;
}
.ml-25px {
  margin-left: 1.5625rem;
}
.ml-26px {
  margin-left: 1.625rem;
}
.ml-27px {
  margin-left: 1.6875rem;
}
.ml-28px {
  margin-left: 1.75rem;
}
.ml-29px {
  margin-left: 1.8125rem;
}
.ml-30px {
  margin-left: 1.875rem;
}
.ml-31px {
  margin-left: 1.9375rem;
}
.ml-32px {
  margin-left: 2rem;
}
.ml-33px {
  margin-left: 2.0625rem;
}
.ml-34px {
  margin-left: 2.125rem;
}
.ml-35px {
  margin-left: 2.1875rem;
}
.ml-36px {
  margin-left: 2.25rem;
}
.ml-37px {
  margin-left: 2.3125rem;
}
.ml-38px {
  margin-left: 2.375rem;
}
.ml-39px {
  margin-left: 2.4375rem;
}
.ml-40px {
  margin-left: 2.5rem;
}
.ml-41px {
  margin-left: 2.5625rem;
}
.ml-42px {
  margin-left: 2.625rem;
}
.ml-43px {
  margin-left: 2.6875rem;
}
.ml-44px {
  margin-left: 2.75rem;
}
.ml-45px {
  margin-left: 2.8125rem;
}
.ml-46px {
  margin-left: 2.875rem;
}
.ml-47px {
  margin-left: 2.9375rem;
}
.ml-48px {
  margin-left: 3rem;
}
.ml-49px {
  margin-left: 3.0625rem;
}
.ml-50px {
  margin-left: 3.125rem;
}
.ml-51px {
  margin-left: 3.1875rem;
}
.ml-52px {
  margin-left: 3.25rem;
}
.ml-53px {
  margin-left: 3.3125rem;
}
.ml-54px {
  margin-left: 3.375rem;
}
.ml-55px {
  margin-left: 3.4375rem;
}
.ml-56px {
  margin-left: 3.5rem;
}
.ml-57px {
  margin-left: 3.5625rem;
}
.ml-58px {
  margin-left: 3.625rem;
}
.ml-59px {
  margin-left: 3.6875rem;
}
.ml-60px {
  margin-left: 3.75rem;
}
.ml-61px {
  margin-left: 3.8125rem;
}
.ml-62px {
  margin-left: 3.875rem;
}
.ml-63px {
  margin-left: 3.9375rem;
}
.ml-64px {
  margin-left: 4rem;
}
.ml-65px {
  margin-left: 4.0625rem;
}
.ml-66px {
  margin-left: 4.125rem;
}
.ml-67px {
  margin-left: 4.1875rem;
}
.ml-68px {
  margin-left: 4.25rem;
}
.ml-69px {
  margin-left: 4.3125rem;
}
.ml-70px {
  margin-left: 4.375rem;
}
.ml-71px {
  margin-left: 4.4375rem;
}
.ml-72px {
  margin-left: 4.5rem;
}
.ml-73px {
  margin-left: 4.5625rem;
}
.ml-74px {
  margin-left: 4.625rem;
}
.ml-75px {
  margin-left: 4.6875rem;
}
.ml-76px {
  margin-left: 4.75rem;
}
.ml-77px {
  margin-left: 4.8125rem;
}
.ml-78px {
  margin-left: 4.875rem;
}
.ml-79px {
  margin-left: 4.9375rem;
}
.ml-80px {
  margin-left: 5rem;
}
.ml-81px {
  margin-left: 5.0625rem;
}
.ml-82px {
  margin-left: 5.125rem;
}
.ml-83px {
  margin-left: 5.1875rem;
}
.ml-84px {
  margin-left: 5.25rem;
}
.ml-85px {
  margin-left: 5.3125rem;
}
.ml-86px {
  margin-left: 5.375rem;
}
.ml-87px {
  margin-left: 5.4375rem;
}
.ml-88px {
  margin-left: 5.5rem;
}
.ml-89px {
  margin-left: 5.5625rem;
}
.ml-90px {
  margin-left: 5.625rem;
}
.ml-91px {
  margin-left: 5.6875rem;
}
.ml-92px {
  margin-left: 5.75rem;
}
.ml-93px {
  margin-left: 5.8125rem;
}
.ml-94px {
  margin-left: 5.875rem;
}
.ml-95px {
  margin-left: 5.9375rem;
}
.ml-96px {
  margin-left: 6rem;
}
.ml-97px {
  margin-left: 6.0625rem;
}
.ml-98px {
  margin-left: 6.125rem;
}
.ml-99px {
  margin-left: 6.1875rem;
}
.ml-100px {
  margin-left: 6.25rem;
}

// right
.mr-none {
  margin-right: 0rem;
}
.mr-0 {
  margin-right: 0;
}
.mr-1px {
  margin-right: 0.0625rem;
}
.mr-2px {
  margin-right: 0.125rem;
}
.mr-3px {
  margin-right: 0.1875rem;
}
.mr-4px {
  margin-right: 0.25rem;
}
.mr-5px {
  margin-right: 0.3125rem;
}
.mr-6px {
  margin-right: 0.375rem;
}
.mr-7px {
  margin-right: 0.4375rem;
}
.mr-8px {
  margin-right: 0.5rem;
}
.mr-9px {
  margin-right: 0.5625rem;
}
.mr-10px {
  margin-right: 0.625rem;
}
.mr-11px {
  margin-right: 0.6875rem;
}
.mr-12px {
  margin-right: 0.75rem;
}
.mr-13px {
  margin-right: 0.8125rem;
}
.mr-14px {
  margin-right: 0.875rem;
}
.mr-15px {
  margin-right: 0.9375rem;
}
.mr-16px {
  margin-right: 1rem;
}
.mr-17px {
  margin-right: 1.0625rem;
}
.mr-18px {
  margin-right: 1.125rem;
}
.mr-19px {
  margin-right: 1.1875rem;
}
.mr-20px {
  margin-right: 1.25rem;
}
.mr-21px {
  margin-right: 1.3125rem;
}
.mr-22px {
  margin-right: 1.375rem;
}
.mr-23px {
  margin-right: 1.4375rem;
}
.mr-24px {
  margin-right: 1.5rem;
}
.mr-25px {
  margin-right: 1.5625rem;
}
.mr-26px {
  margin-right: 1.625rem;
}
.mr-27px {
  margin-right: 1.6875rem;
}
.mr-28px {
  margin-right: 1.75rem;
}
.mr-29px {
  margin-right: 1.8125rem;
}
.mr-30px {
  margin-right: 1.875rem;
}
.mr-31px {
  margin-right: 1.9375rem;
}
.mr-32px {
  margin-right: 2rem;
}
.mr-33px {
  margin-right: 2.0625rem;
}
.mr-34px {
  margin-right: 2.125rem;
}
.mr-35px {
  margin-right: 2.1875rem;
}
.mr-36px {
  margin-right: 2.25rem;
}
.mr-37px {
  margin-right: 2.3125rem;
}
.mr-38px {
  margin-right: 2.375rem;
}
.mr-39px {
  margin-right: 2.4375rem;
}
.mr-40px {
  margin-right: 2.5rem;
}
.mr-41px {
  margin-right: 2.5625rem;
}
.mr-42px {
  margin-right: 2.625rem;
}
.mr-43px {
  margin-right: 2.6875rem;
}
.mr-44px {
  margin-right: 2.75rem;
}
.mr-45px {
  margin-right: 2.8125rem;
}
.mr-46px {
  margin-right: 2.875rem;
}
.mr-47px {
  margin-right: 2.9375rem;
}
.mr-48px {
  margin-right: 3rem;
}
.mr-49px {
  margin-right: 3.0625rem;
}
.mr-50px {
  margin-right: 3.125rem;
}
.mr-51px {
  margin-right: 3.1875rem;
}
.mr-52px {
  margin-right: 3.25rem;
}
.mr-53px {
  margin-right: 3.3125rem;
}
.mr-54px {
  margin-right: 3.375rem;
}
.mr-55px {
  margin-right: 3.4375rem;
}
.mr-56px {
  margin-right: 3.5rem;
}
.mr-57px {
  margin-right: 3.5625rem;
}
.mr-58px {
  margin-right: 3.625rem;
}
.mr-59px {
  margin-right: 3.6875rem;
}
.mr-60px {
  margin-right: 3.75rem;
}
.mr-61px {
  margin-right: 3.8125rem;
}
.mr-62px {
  margin-right: 3.875rem;
}
.mr-63px {
  margin-right: 3.9375rem;
}
.mr-64px {
  margin-right: 4rem;
}
.mr-65px {
  margin-right: 4.0625rem;
}
.mr-66px {
  margin-right: 4.125rem;
}
.mr-67px {
  margin-right: 4.1875rem;
}
.mr-68px {
  margin-right: 4.25rem;
}
.mr-69px {
  margin-right: 4.3125rem;
}
.mr-70px {
  margin-right: 4.375rem;
}
.mr-71px {
  margin-right: 4.4375rem;
}
.mr-72px {
  margin-right: 4.5rem;
}
.mr-73px {
  margin-right: 4.5625rem;
}
.mr-74px {
  margin-right: 4.625rem;
}
.mr-75px {
  margin-right: 4.6875rem;
}
.mr-76px {
  margin-right: 4.75rem;
}
.mr-77px {
  margin-right: 4.8125rem;
}
.mr-78px {
  margin-right: 4.875rem;
}
.mr-79px {
  margin-right: 4.9375rem;
}
.mr-80px {
  margin-right: 5rem;
}
.mr-81px {
  margin-right: 5.0625rem;
}
.mr-82px {
  margin-right: 5.125rem;
}
.mr-83px {
  margin-right: 5.1875rem;
}
.mr-84px {
  margin-right: 5.25rem;
}
.mr-85px {
  margin-right: 5.3125rem;
}
.mr-86px {
  margin-right: 5.375rem;
}
.mr-87px {
  margin-right: 5.4375rem;
}
.mr-88px {
  margin-right: 5.5rem;
}
.mr-89px {
  margin-right: 5.5625rem;
}
.mr-90px {
  margin-right: 5.625rem;
}
.mr-91px {
  margin-right: 5.6875rem;
}
.mr-92px {
  margin-right: 5.75rem;
}
.mr-93px {
  margin-right: 5.8125rem;
}
.mr-94px {
  margin-right: 5.875rem;
}
.mr-95px {
  margin-right: 5.9375rem;
}
.mr-96px {
  margin-right: 6rem;
}
.mr-97px {
  margin-right: 6.0625rem;
}
.mr-98px {
  margin-right: 6.125rem;
}
.mr-99px {
  margin-right: 6.1875rem;
}
.mr-100px {
  margin-right: 6.25rem;
}

// bottom
.mb-none {
  margin-bottom: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1px {
  margin-bottom: 0.0625rem;
}
.mb-2px {
  margin-bottom: 0.125rem;
}
.mb-3px {
  margin-bottom: 0.1875rem;
}
.mb-4px {
  margin-bottom: 0.25rem;
}
.mb-5px {
  margin-bottom: 0.3125rem;
}
.mb-6px {
  margin-bottom: 0.375rem;
}
.mb-7px {
  margin-bottom: 0.4375rem;
}
.mb-8px {
  margin-bottom: 0.5rem;
}
.mb-9px {
  margin-bottom: 0.5625rem;
}
.mb-10px {
  margin-bottom: 0.625rem;
}
.mb-11px {
  margin-bottom: 0.6875rem;
}
.mb-12px {
  margin-bottom: 0.75rem;
}
.mb-13px {
  margin-bottom: 0.8125rem;
}
.mb-14px {
  margin-bottom: 0.875rem;
}
.mb-15px {
  margin-bottom: 0.9375rem;
}
.mb-16px {
  margin-bottom: 1rem;
}
.mb-17px {
  margin-bottom: 1.0625rem;
}
.mb-18px {
  margin-bottom: 1.125rem;
}
.mb-19px {
  margin-bottom: 1.1875rem;
}
.mb-20px {
  margin-bottom: 1.25rem;
}
.mb-21px {
  margin-bottom: 1.3125rem;
}
.mb-22px {
  margin-bottom: 1.375rem;
}
.mb-23px {
  margin-bottom: 1.4375rem;
}
.mb-24px {
  margin-bottom: 1.5rem;
}
.mb-25px {
  margin-bottom: 1.5625rem;
}
.mb-26px {
  margin-bottom: 1.625rem;
}
.mb-27px {
  margin-bottom: 1.6875rem;
}
.mb-28px {
  margin-bottom: 1.75rem;
}
.mb-29px {
  margin-bottom: 1.8125rem;
}
.mb-30px {
  margin-bottom: 1.875rem;
}
.mb-31px {
  margin-bottom: 1.9375rem;
}
.mb-32px {
  margin-bottom: 2rem;
}
.mb-33px {
  margin-bottom: 2.0625rem;
}
.mb-34px {
  margin-bottom: 2.125rem;
}
.mb-35px {
  margin-bottom: 2.1875rem;
}
.mb-36px {
  margin-bottom: 2.25rem;
}
.mb-37px {
  margin-bottom: 2.3125rem;
}
.mb-38px {
  margin-bottom: 2.375rem;
}
.mb-39px {
  margin-bottom: 2.4375rem;
}
.mb-40px {
  margin-bottom: 2.5rem;
}
.mb-41px {
  margin-bottom: 2.5625rem;
}
.mb-42px {
  margin-bottom: 2.625rem;
}
.mb-43px {
  margin-bottom: 2.6875rem;
}
.mb-44px {
  margin-bottom: 2.75rem;
}
.mb-45px {
  margin-bottom: 2.8125rem;
}
.mb-46px {
  margin-bottom: 2.875rem;
}
.mb-47px {
  margin-bottom: 2.9375rem;
}
.mb-48px {
  margin-bottom: 3rem;
}
.mb-49px {
  margin-bottom: 3.0625rem;
}
.mb-50px {
  margin-bottom: 3.125rem;
}
.mb-51px {
  margin-bottom: 3.1875rem;
}
.mb-52px {
  margin-bottom: 3.25rem;
}
.mb-53px {
  margin-bottom: 3.3125rem;
}
.mb-54px {
  margin-bottom: 3.375rem;
}
.mb-55px {
  margin-bottom: 3.4375rem;
}
.mb-56px {
  margin-bottom: 3.5rem;
}
.mb-57px {
  margin-bottom: 3.5625rem;
}
.mb-58px {
  margin-bottom: 3.625rem;
}
.mb-59px {
  margin-bottom: 3.6875rem;
}
.mb-60px {
  margin-bottom: 3.75rem;
}
.mb-61px {
  margin-bottom: 3.8125rem;
}
.mb-62px {
  margin-bottom: 3.875rem;
}
.mb-63px {
  margin-bottom: 3.9375rem;
}
.mb-64px {
  margin-bottom: 4rem;
}
.mb-65px {
  margin-bottom: 4.0625rem;
}
.mb-66px {
  margin-bottom: 4.125rem;
}
.mb-67px {
  margin-bottom: 4.1875rem;
}
.mb-68px {
  margin-bottom: 4.25rem;
}
.mb-69px {
  margin-bottom: 4.3125rem;
}
.mb-70px {
  margin-bottom: 4.375rem;
}
.mb-71px {
  margin-bottom: 4.4375rem;
}
.mb-72px {
  margin-bottom: 4.5rem;
}
.mb-73px {
  margin-bottom: 4.5625rem;
}
.mb-74px {
  margin-bottom: 4.625rem;
}
.mb-75px {
  margin-bottom: 4.6875rem;
}
.mb-76px {
  margin-bottom: 4.75rem;
}
.mb-77px {
  margin-bottom: 4.8125rem;
}
.mb-78px {
  margin-bottom: 4.875rem;
}
.mb-79px {
  margin-bottom: 4.9375rem;
}
.mb-80px {
  margin-bottom: 5rem;
}
.mb-81px {
  margin-bottom: 5.0625rem;
}
.mb-82px {
  margin-bottom: 5.125rem;
}
.mb-83px {
  margin-bottom: 5.1875rem;
}
.mb-84px {
  margin-bottom: 5.25rem;
}
.mb-85px {
  margin-bottom: 5.3125rem;
}
.mb-86px {
  margin-bottom: 5.375rem;
}
.mb-87px {
  margin-bottom: 5.4375rem;
}
.mb-88px {
  margin-bottom: 5.5rem;
}
.mb-89px {
  margin-bottom: 5.5625rem;
}
.mb-90px {
  margin-bottom: 5.625rem;
}
.mb-91px {
  margin-bottom: 5.6875rem;
}
.mb-92px {
  margin-bottom: 5.75rem;
}
.mb-93px {
  margin-bottom: 5.8125rem;
}
.mb-94px {
  margin-bottom: 5.875rem;
}
.mb-95px {
  margin-bottom: 5.9375rem;
}
.mb-96px {
  margin-bottom: 6rem;
}
.mb-97px {
  margin-bottom: 6.0625rem;
}
.mb-98px {
  margin-bottom: 6.125rem;
}
.mb-99px {
  margin-bottom: 6.1875rem;
}
.mb-100px {
  margin-bottom: 6.25rem;
}
