.animating {
  animation: 0.75s flicker-white-color infinite alternate;
}
.dark-animation.animating {
  animation: 0.75s flicker-dark-color infinite alternate;
}

@keyframes flicker-white-color {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  10% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  20% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  30% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  40% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.6);
  }
  60% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  70% {
    background-color: rgba(255, 255, 255, 0.4);
  }
  80% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  90% {
    background-color: rgba(255, 255, 255, 0.2);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@keyframes flicker-dark-color {
  0% {
    background-color: rgba(200, 200, 200, 0.1);
  }
  10% {
    background-color: rgba(200, 200, 200, 0.2);
  }
  20% {
    background-color: rgba(200, 200, 200, 0.3);
  }
  30% {
    background-color: rgba(200, 200, 200, 0.4);
  }
  40% {
    background-color: rgba(200, 200, 200, 0.5);
  }
  50% {
    background-color: rgba(200, 200, 200, 0.6);
  }
  60% {
    background-color: rgba(200, 200, 200, 0.5);
  }
  70% {
    background-color: rgba(200, 200, 200, 0.4);
  }
  80% {
    background-color: rgba(200, 200, 200, 0.3);
  }
  90% {
    background-color: rgba(200, 200, 200, 0.2);
  }
  100% {
    background-color: rgba(200, 200, 200, 0.1);
  }
}

// Spinning

@-moz-keyframes spin-ccw {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin-ccw {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-ccw {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-moz-keyframes half-spin-ccw {
  100% {
    -moz-transform: rotate(-180deg);
  }
}
@-webkit-keyframes half-spin-ccw {
  100% {
    -webkit-transform: rotate(-180deg);
  }
}
@keyframes half-spin-ccw {
  100% {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
}

@-moz-keyframes spin-cw {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-cw {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin-cw {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
