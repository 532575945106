@use 'variables' as v;

:root {
  // all
  --button-font-family: poppins;
  --button-font-weight: 600;
  --button-font-style: normal;
  // info text
  --button-info-font-family: poppins;
  --button-info-font-weight: 600;
  --button-info-font-style: normal;
  --button-info-font-size: 0.75rem;
  // defaults
  --button-default-text-color: #{v.$button-text-color};
  --button-default-background-color: #{v.$bs-black-color};
  --button-default-hover-color: #{v.$button-text-hover-color};
  --button-default-padding: 0.75rem 1rem;
  --button-default-border-radius: 0.625rem;
  --button-default-height: 2.75rem;
  --button-default-font-size: 0.875rem;
  // button rounded
  --button-rounded-text-color: #{v.$bs-white-color};
  --button-rounded-background-color: #{v.$bs-dark-gray-color};
  --button-rounded-hover-color: #{v.$bs-extra-dark-gray-color};
  --button-rounded-disabled-background-color: #{v.$bs-default-gray-color};
  // clear
  --button-clear-text-color: #{v.$bs-black-color};
  --button-clear-font-family: poppins;
  --button-clear-font-weight: 500;
  --button-clear-font-style: normal;
  // destructive
  --button-destructive-text-color: #{v.$bs-red-color};
  --button-destructive-background-color: #{v.$bs-white-color};
  --button-destructive-disabled-background-color: #{v.$bs-red-color};
  // neutral
  --button-neutral-background-color: #{v.$button-neutral-color};
  --button-neutral-text-color: #{v.$button-black-text-color};
  --button-neutral-hover-background-color: #{v.$button-neutral-hover-color};
  --button-neutral-disabled-background-color: #{v.$button-neutral-color};
  // outlined
  --button-outlined-background-color: #{v.$bs-white-color};
  --button-outlined-text-color: #{v.$button-black-text-color};
  --button-outlined-hover-background-color: #{v.$button-outlined-hover-color};
  // primary
  --button-primary-text-color: #{v.$bs-white-color};
  --button-primary-background-color: #{v.$bs-primary-blue-color};
  --button-primary-hover-color: #{v.$button-preferred-hover-color};
  --button-primary-disabled-background-color: #{v.$bs-primary-blue-color};
  // text
  --button-text-color: #{v.$button-black-text-color};
  --button-text-background-color: #{v.$button-text-color};
  --button-text-hover-color: #{v.$button-text-hover-color};
  // inline text
  --button-inline-text-color: #{v.$button-black-text-color};
  --button-inline-text-font-weight: 500;
  --button-inline-text-background-color: v.$bs-transparent-color;
  --button-inline-text-hover-color: v.$bs-transparent-color;
}

/**
 * Force button containers in the reactive-buttons library to have white backgrounds, so that when they go into their
 * disabled states (which uses opacity), you won't be able to see through them.
 */
.button-container {
  background-color: white !important;
  border-radius: var(--button-default-border-radius);
}
