.toast-container .bs-toast.ngx-toastr.toast-success {
  background-color: $toastr-success-color;
}

.toast-container .bs-toast.ngx-toastr.toast-info {
  background-color: $toastr-info-color;
}

.toast-container .bs-toast.ngx-toastr.toast-warning {
  background-color: $toastr-warning-color;
}

.toast-container .bs-toast.ngx-toastr.toast-error {
  background-color: $toastr-error-color;
}
