.no-padding {
  padding: 0;
}
.padding-none {
  padding: 0;
}
.p-none {
  padding: 0;
}
.p-0 {
  padding: 0;
}

// top
.pt-none {
  padding-top: 0;
}
.pt-0 {
  padding-top: 0;
}
.pt-1px {
  padding-top: 0.0625rem;
}
.pt-2px {
  padding-top: 0.125rem;
}
.pt-3px {
  padding-top: 0.1875rem;
}
.pt-4px {
  padding-top: 0.25rem;
}
.pt-5px {
  padding-top: 0.3125rem;
}
.pt-6px {
  padding-top: 0.375rem;
}
.pt-7px {
  padding-top: 0.4375rem;
}
.pt-8px {
  padding-top: 0.5rem;
}
.pt-9px {
  padding-top: 0.5625rem;
}
.pt-10px {
  padding-top: 0.625rem;
}
.pt-11px {
  padding-top: 0.6875rem;
}
.pt-12px {
  padding-top: 0.75rem;
}
.pt-13px {
  padding-top: 0.8125rem;
}
.pt-14px {
  padding-top: 0.875rem;
}
.pt-15px {
  padding-top: 0.9375rem;
}
.pt-16px {
  padding-top: 1rem;
}
.pt-17px {
  padding-top: 1.0625rem;
}
.pt-18px {
  padding-top: 1.125rem;
}
.pt-19px {
  padding-top: 1.1875rem;
}
.pt-20px {
  padding-top: 1.25rem;
}
.pt-21px {
  padding-top: 1.3125rem;
}
.pt-22px {
  padding-top: 1.375rem;
}
.pt-23px {
  padding-top: 1.4375rem;
}
.pt-24px {
  padding-top: 1.5rem;
}
.pt-25px {
  padding-top: 1.5625rem;
}
.pt-26px {
  padding-top: 1.625rem;
}
.pt-27px {
  padding-top: 1.6875rem;
}
.pt-28px {
  padding-top: 1.75rem;
}
.pt-29px {
  padding-top: 1.8125rem;
}
.pt-30px {
  padding-top: 1.875rem;
}
.pt-31px {
  padding-top: 1.9375rem;
}
.pt-32px {
  padding-top: 2rem;
}
.pt-33px {
  padding-top: 2.0625rem;
}
.pt-34px {
  padding-top: 2.125rem;
}
.pt-35px {
  padding-top: 2.1875rem;
}
.pt-36px {
  padding-top: 2.25rem;
}
.pt-37px {
  padding-top: 2.3125rem;
}
.pt-38px {
  padding-top: 2.375rem;
}
.pt-39px {
  padding-top: 2.4375rem;
}
.pt-40px {
  padding-top: 2.5rem;
}
.pt-41px {
  padding-top: 2.5625rem;
}
.pt-42px {
  padding-top: 2.625rem;
}
.pt-43px {
  padding-top: 2.6875rem;
}
.pt-44px {
  padding-top: 2.75rem;
}
.pt-45px {
  padding-top: 2.8125rem;
}
.pt-46px {
  padding-top: 2.875rem;
}
.pt-47px {
  padding-top: 2.9375rem;
}
.pt-48px {
  padding-top: 3rem;
}
.pt-49px {
  padding-top: 3.0625rem;
}
.pt-50px {
  padding-top: 3.125rem;
}
.pt-51px {
  padding-top: 3.1875rem;
}
.pt-52px {
  padding-top: 3.25rem;
}
.pt-53px {
  padding-top: 3.3125rem;
}
.pt-54px {
  padding-top: 3.375rem;
}
.pt-55px {
  padding-top: 3.4375rem;
}
.pt-56px {
  padding-top: 3.5rem;
}
.pt-57px {
  padding-top: 3.5625rem;
}
.pt-58px {
  padding-top: 3.625rem;
}
.pt-59px {
  padding-top: 3.6875rem;
}
.pt-60px {
  padding-top: 3.75rem;
}
.pt-61px {
  padding-top: 3.8125rem;
}
.pt-62px {
  padding-top: 3.875rem;
}
.pt-63px {
  padding-top: 3.9375rem;
}
.pt-64px {
  padding-top: 4rem;
}
.pt-65px {
  padding-top: 4.0625rem;
}
.pt-66px {
  padding-top: 4.125rem;
}
.pt-67px {
  padding-top: 4.1875rem;
}
.pt-68px {
  padding-top: 4.25rem;
}
.pt-69px {
  padding-top: 4.3125rem;
}
.pt-70px {
  padding-top: 4.375rem;
}
.pt-71px {
  padding-top: 4.4375rem;
}
.pt-72px {
  padding-top: 4.5rem;
}
.pt-73px {
  padding-top: 4.5625rem;
}
.pt-74px {
  padding-top: 4.625rem;
}
.pt-75px {
  padding-top: 4.6875rem;
}
.pt-76px {
  padding-top: 4.75rem;
}
.pt-77px {
  padding-top: 4.8125rem;
}
.pt-78px {
  padding-top: 4.875rem;
}
.pt-79px {
  padding-top: 4.9375rem;
}
.pt-80px {
  padding-top: 5rem;
}
.pt-81px {
  padding-top: 5.0625rem;
}
.pt-82px {
  padding-top: 5.125rem;
}
.pt-83px {
  padding-top: 5.1875rem;
}
.pt-84px {
  padding-top: 5.25rem;
}
.pt-85px {
  padding-top: 5.3125rem;
}
.pt-86px {
  padding-top: 5.375rem;
}
.pt-87px {
  padding-top: 5.4375rem;
}
.pt-88px {
  padding-top: 5.5rem;
}
.pt-89px {
  padding-top: 5.5625rem;
}
.pt-90px {
  padding-top: 5.625rem;
}
.pt-91px {
  padding-top: 5.6875rem;
}
.pt-92px {
  padding-top: 5.75rem;
}
.pt-93px {
  padding-top: 5.8125rem;
}
.pt-94px {
  padding-top: 5.875rem;
}
.pt-95px {
  padding-top: 5.9375rem;
}
.pt-96px {
  padding-top: 6rem;
}
.pt-97px {
  padding-top: 6.0625rem;
}
.pt-98px {
  padding-top: 6.125rem;
}
.pt-99px {
  padding-top: 6.1875rem;
}
.pt-100px {
  padding-top: 6.25rem;
}

// left
.pl-none {
  padding-left: 0;
}
.pl-0 {
  padding-left: 0;
}
.pl-1px {
  padding-left: 0.0625rem;
}
.pl-2px {
  padding-left: 0.125rem;
}
.pl-3px {
  padding-left: 0.1875rem;
}
.pl-4px {
  padding-left: 0.25rem;
}
.pl-5px {
  padding-left: 0.3125rem;
}
.pl-6px {
  padding-left: 0.375rem;
}
.pl-7px {
  padding-left: 0.4375rem;
}
.pl-8px {
  padding-left: 0.5rem;
}
.pl-9px {
  padding-left: 0.5625rem;
}
.pl-10px {
  padding-left: 0.625rem;
}
.pl-11px {
  padding-left: 0.6875rem;
}
.pl-12px {
  padding-left: 0.75rem;
}
.pl-13px {
  padding-left: 0.8125rem;
}
.pl-14px {
  padding-left: 0.875rem;
}
.pl-15px {
  padding-left: 0.9375rem;
}
.pl-16px {
  padding-left: 1rem;
}
.pl-17px {
  padding-left: 1.0625rem;
}
.pl-18px {
  padding-left: 1.125rem;
}
.pl-19px {
  padding-left: 1.1875rem;
}
.pl-20px {
  padding-left: 1.25rem;
}
.pl-21px {
  padding-left: 1.3125rem;
}
.pl-22px {
  padding-left: 1.375rem;
}
.pl-23px {
  padding-left: 1.4375rem;
}
.pl-24px {
  padding-left: 1.5rem;
}
.pl-25px {
  padding-left: 1.5625rem;
}
.pl-26px {
  padding-left: 1.625rem;
}
.pl-27px {
  padding-left: 1.6875rem;
}
.pl-28px {
  padding-left: 1.75rem;
}
.pl-29px {
  padding-left: 1.8125rem;
}
.pl-30px {
  padding-left: 1.875rem;
}
.pl-31px {
  padding-left: 1.9375rem;
}
.pl-32px {
  padding-left: 2rem;
}
.pl-33px {
  padding-left: 2.0625rem;
}
.pl-34px {
  padding-left: 2.125rem;
}
.pl-35px {
  padding-left: 2.1875rem;
}
.pl-36px {
  padding-left: 2.25rem;
}
.pl-37px {
  padding-left: 2.3125rem;
}
.pl-38px {
  padding-left: 2.375rem;
}
.pl-39px {
  padding-left: 2.4375rem;
}
.pl-40px {
  padding-left: 2.5rem;
}
.pl-41px {
  padding-left: 2.5625rem;
}
.pl-42px {
  padding-left: 2.625rem;
}
.pl-43px {
  padding-left: 2.6875rem;
}
.pl-44px {
  padding-left: 2.75rem;
}
.pl-45px {
  padding-left: 2.8125rem;
}
.pl-46px {
  padding-left: 2.875rem;
}
.pl-47px {
  padding-left: 2.9375rem;
}
.pl-48px {
  padding-left: 3rem;
}
.pl-49px {
  padding-left: 3.0625rem;
}
.pl-50px {
  padding-left: 3.125rem;
}
.pl-51px {
  padding-left: 3.1875rem;
}
.pl-52px {
  padding-left: 3.25rem;
}
.pl-53px {
  padding-left: 3.3125rem;
}
.pl-54px {
  padding-left: 3.375rem;
}
.pl-55px {
  padding-left: 3.4375rem;
}
.pl-56px {
  padding-left: 3.5rem;
}
.pl-57px {
  padding-left: 3.5625rem;
}
.pl-58px {
  padding-left: 3.625rem;
}
.pl-59px {
  padding-left: 3.6875rem;
}
.pl-60px {
  padding-left: 3.75rem;
}
.pl-61px {
  padding-left: 3.8125rem;
}
.pl-62px {
  padding-left: 3.875rem;
}
.pl-63px {
  padding-left: 3.9375rem;
}
.pl-64px {
  padding-left: 4rem;
}
.pl-65px {
  padding-left: 4.0625rem;
}
.pl-66px {
  padding-left: 4.125rem;
}
.pl-67px {
  padding-left: 4.1875rem;
}
.pl-68px {
  padding-left: 4.25rem;
}
.pl-69px {
  padding-left: 4.3125rem;
}
.pl-70px {
  padding-left: 4.375rem;
}
.pl-71px {
  padding-left: 4.4375rem;
}
.pl-72px {
  padding-left: 4.5rem;
}
.pl-73px {
  padding-left: 4.5625rem;
}
.pl-74px {
  padding-left: 4.625rem;
}
.pl-75px {
  padding-left: 4.6875rem;
}
.pl-76px {
  padding-left: 4.75rem;
}
.pl-77px {
  padding-left: 4.8125rem;
}
.pl-78px {
  padding-left: 4.875rem;
}
.pl-79px {
  padding-left: 4.9375rem;
}
.pl-80px {
  padding-left: 5rem;
}
.pl-81px {
  padding-left: 5.0625rem;
}
.pl-82px {
  padding-left: 5.125rem;
}
.pl-83px {
  padding-left: 5.1875rem;
}
.pl-84px {
  padding-left: 5.25rem;
}
.pl-85px {
  padding-left: 5.3125rem;
}
.pl-86px {
  padding-left: 5.375rem;
}
.pl-87px {
  padding-left: 5.4375rem;
}
.pl-88px {
  padding-left: 5.5rem;
}
.pl-89px {
  padding-left: 5.5625rem;
}
.pl-90px {
  padding-left: 5.625rem;
}
.pl-91px {
  padding-left: 5.6875rem;
}
.pl-92px {
  padding-left: 5.75rem;
}
.pl-93px {
  padding-left: 5.8125rem;
}
.pl-94px {
  padding-left: 5.875rem;
}
.pl-95px {
  padding-left: 5.9375rem;
}
.pl-96px {
  padding-left: 6rem;
}
.pl-97px {
  padding-left: 6.0625rem;
}
.pl-98px {
  padding-left: 6.125rem;
}
.pl-99px {
  padding-left: 6.1875rem;
}
.pl-100px {
  padding-left: 6.25rem;
}

// right
.pr-none {
  padding-right: 0;
}
.pr-0 {
  padding-right: 0;
}
.pr-1px {
  padding-right: 0.0625rem;
}
.pr-2px {
  padding-right: 0.125rem;
}
.pr-3px {
  padding-right: 0.1875rem;
}
.pr-4px {
  padding-right: 0.25rem;
}
.pr-5px {
  padding-right: 0.3125rem;
}
.pr-6px {
  padding-right: 0.375rem;
}
.pr-7px {
  padding-right: 0.4375rem;
}
.pr-8px {
  padding-right: 0.5rem;
}
.pr-9px {
  padding-right: 0.5625rem;
}
.pr-10px {
  padding-right: 0.625rem;
}
.pr-11px {
  padding-right: 0.6875rem;
}
.pr-12px {
  padding-right: 0.75rem;
}
.pr-13px {
  padding-right: 0.8125rem;
}
.pr-14px {
  padding-right: 0.875rem;
}
.pr-15px {
  padding-right: 0.9375rem;
}
.pr-16px {
  padding-right: 1rem;
}
.pr-17px {
  padding-right: 1.0625rem;
}
.pr-18px {
  padding-right: 1.125rem;
}
.pr-19px {
  padding-right: 1.1875rem;
}
.pr-20px {
  padding-right: 1.25rem;
}
.pr-21px {
  padding-right: 1.3125rem;
}
.pr-22px {
  padding-right: 1.375rem;
}
.pr-23px {
  padding-right: 1.4375rem;
}
.pr-24px {
  padding-right: 1.5rem;
}
.pr-25px {
  padding-right: 1.5625rem;
}
.pr-26px {
  padding-right: 1.625rem;
}
.pr-27px {
  padding-right: 1.6875rem;
}
.pr-28px {
  padding-right: 1.75rem;
}
.pr-29px {
  padding-right: 1.8125rem;
}
.pr-30px {
  padding-right: 1.875rem;
}
.pr-31px {
  padding-right: 1.9375rem;
}
.pr-32px {
  padding-right: 2rem;
}
.pr-33px {
  padding-right: 2.0625rem;
}
.pr-34px {
  padding-right: 2.125rem;
}
.pr-35px {
  padding-right: 2.1875rem;
}
.pr-36px {
  padding-right: 2.25rem;
}
.pr-37px {
  padding-right: 2.3125rem;
}
.pr-38px {
  padding-right: 2.375rem;
}
.pr-39px {
  padding-right: 2.4375rem;
}
.pr-40px {
  padding-right: 2.5rem;
}
.pr-41px {
  padding-right: 2.5625rem;
}
.pr-42px {
  padding-right: 2.625rem;
}
.pr-43px {
  padding-right: 2.6875rem;
}
.pr-44px {
  padding-right: 2.75rem;
}
.pr-45px {
  padding-right: 2.8125rem;
}
.pr-46px {
  padding-right: 2.875rem;
}
.pr-47px {
  padding-right: 2.9375rem;
}
.pr-48px {
  padding-right: 3rem;
}
.pr-49px {
  padding-right: 3.0625rem;
}
.pr-50px {
  padding-right: 3.125rem;
}
.pr-51px {
  padding-right: 3.1875rem;
}
.pr-52px {
  padding-right: 3.25rem;
}
.pr-53px {
  padding-right: 3.3125rem;
}
.pr-54px {
  padding-right: 3.375rem;
}
.pr-55px {
  padding-right: 3.4375rem;
}
.pr-56px {
  padding-right: 3.5rem;
}
.pr-57px {
  padding-right: 3.5625rem;
}
.pr-58px {
  padding-right: 3.625rem;
}
.pr-59px {
  padding-right: 3.6875rem;
}
.pr-60px {
  padding-right: 3.75rem;
}
.pr-61px {
  padding-right: 3.8125rem;
}
.pr-62px {
  padding-right: 3.875rem;
}
.pr-63px {
  padding-right: 3.9375rem;
}
.pr-64px {
  padding-right: 4rem;
}
.pr-65px {
  padding-right: 4.0625rem;
}
.pr-66px {
  padding-right: 4.125rem;
}
.pr-67px {
  padding-right: 4.1875rem;
}
.pr-68px {
  padding-right: 4.25rem;
}
.pr-69px {
  padding-right: 4.3125rem;
}
.pr-70px {
  padding-right: 4.375rem;
}
.pr-71px {
  padding-right: 4.4375rem;
}
.pr-72px {
  padding-right: 4.5rem;
}
.pr-73px {
  padding-right: 4.5625rem;
}
.pr-74px {
  padding-right: 4.625rem;
}
.pr-75px {
  padding-right: 4.6875rem;
}
.pr-76px {
  padding-right: 4.75rem;
}
.pr-77px {
  padding-right: 4.8125rem;
}
.pr-78px {
  padding-right: 4.875rem;
}
.pr-79px {
  padding-right: 4.9375rem;
}
.pr-80px {
  padding-right: 5rem;
}
.pr-81px {
  padding-right: 5.0625rem;
}
.pr-82px {
  padding-right: 5.125rem;
}
.pr-83px {
  padding-right: 5.1875rem;
}
.pr-84px {
  padding-right: 5.25rem;
}
.pr-85px {
  padding-right: 5.3125rem;
}
.pr-86px {
  padding-right: 5.375rem;
}
.pr-87px {
  padding-right: 5.4375rem;
}
.pr-88px {
  padding-right: 5.5rem;
}
.pr-89px {
  padding-right: 5.5625rem;
}
.pr-90px {
  padding-right: 5.625rem;
}
.pr-91px {
  padding-right: 5.6875rem;
}
.pr-92px {
  padding-right: 5.75rem;
}
.pr-93px {
  padding-right: 5.8125rem;
}
.pr-94px {
  padding-right: 5.875rem;
}
.pr-95px {
  padding-right: 5.9375rem;
}
.pr-96px {
  padding-right: 6rem;
}
.pr-97px {
  padding-right: 6.0625rem;
}
.pr-98px {
  padding-right: 6.125rem;
}
.pr-99px {
  padding-right: 6.1875rem;
}
.pr-100px {
  padding-right: 6.25rem;
}

// bottom
.pb-none {
  padding-bottom: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1px {
  padding-bottom: 0.0625rem;
}
.pb-2px {
  padding-bottom: 0.125rem;
}
.pb-3px {
  padding-bottom: 0.1875rem;
}
.pb-4px {
  padding-bottom: 0.25rem;
}
.pb-5px {
  padding-bottom: 0.3125rem;
}
.pb-6px {
  padding-bottom: 0.375rem;
}
.pb-7px {
  padding-bottom: 0.4375rem;
}
.pb-8px {
  padding-bottom: 0.5rem;
}
.pb-9px {
  padding-bottom: 0.5625rem;
}
.pb-10px {
  padding-bottom: 0.625rem;
}
.pb-11px {
  padding-bottom: 0.6875rem;
}
.pb-12px {
  padding-bottom: 0.75rem;
}
.pb-13px {
  padding-bottom: 0.8125rem;
}
.pb-14px {
  padding-bottom: 0.875rem;
}
.pb-15px {
  padding-bottom: 0.9375rem;
}
.pb-16px {
  padding-bottom: 1rem;
}
.pb-17px {
  padding-bottom: 1.0625rem;
}
.pb-18px {
  padding-bottom: 1.125rem;
}
.pb-19px {
  padding-bottom: 1.1875rem;
}
.pb-20px {
  padding-bottom: 1.25rem;
}
.pb-21px {
  padding-bottom: 1.3125rem;
}
.pb-22px {
  padding-bottom: 1.375rem;
}
.pb-23px {
  padding-bottom: 1.4375rem;
}
.pb-24px {
  padding-bottom: 1.5rem;
}
.pb-25px {
  padding-bottom: 1.5625rem;
}
.pb-26px {
  padding-bottom: 1.625rem;
}
.pb-27px {
  padding-bottom: 1.6875rem;
}
.pb-28px {
  padding-bottom: 1.75rem;
}
.pb-29px {
  padding-bottom: 1.8125rem;
}
.pb-30px {
  padding-bottom: 1.875rem;
}
.pb-31px {
  padding-bottom: 1.9375rem;
}
.pb-32px {
  padding-bottom: 2rem;
}
.pb-33px {
  padding-bottom: 2.0625rem;
}
.pb-34px {
  padding-bottom: 2.125rem;
}
.pb-35px {
  padding-bottom: 2.1875rem;
}
.pb-36px {
  padding-bottom: 2.25rem;
}
.pb-37px {
  padding-bottom: 2.3125rem;
}
.pb-38px {
  padding-bottom: 2.375rem;
}
.pb-39px {
  padding-bottom: 2.4375rem;
}
.pb-40px {
  padding-bottom: 2.5rem;
}
.pb-41px {
  padding-bottom: 2.5625rem;
}
.pb-42px {
  padding-bottom: 2.625rem;
}
.pb-43px {
  padding-bottom: 2.6875rem;
}
.pb-44px {
  padding-bottom: 2.75rem;
}
.pb-45px {
  padding-bottom: 2.8125rem;
}
.pb-46px {
  padding-bottom: 2.875rem;
}
.pb-47px {
  padding-bottom: 2.9375rem;
}
.pb-48px {
  padding-bottom: 3rem;
}
.pb-49px {
  padding-bottom: 3.0625rem;
}
.pb-50px {
  padding-bottom: 3.125rem;
}
.pb-51px {
  padding-bottom: 3.1875rem;
}
.pb-52px {
  padding-bottom: 3.25rem;
}
.pb-53px {
  padding-bottom: 3.3125rem;
}
.pb-54px {
  padding-bottom: 3.375rem;
}
.pb-55px {
  padding-bottom: 3.4375rem;
}
.pb-56px {
  padding-bottom: 3.5rem;
}
.pb-57px {
  padding-bottom: 3.5625rem;
}
.pb-58px {
  padding-bottom: 3.625rem;
}
.pb-59px {
  padding-bottom: 3.6875rem;
}
.pb-60px {
  padding-bottom: 3.75rem;
}
.pb-61px {
  padding-bottom: 3.8125rem;
}
.pb-62px {
  padding-bottom: 3.875rem;
}
.pb-63px {
  padding-bottom: 3.9375rem;
}
.pb-64px {
  padding-bottom: 4rem;
}
.pb-65px {
  padding-bottom: 4.0625rem;
}
.pb-66px {
  padding-bottom: 4.125rem;
}
.pb-67px {
  padding-bottom: 4.1875rem;
}
.pb-68px {
  padding-bottom: 4.25rem;
}
.pb-69px {
  padding-bottom: 4.3125rem;
}
.pb-70px {
  padding-bottom: 4.375rem;
}
.pb-71px {
  padding-bottom: 4.4375rem;
}
.pb-72px {
  padding-bottom: 4.5rem;
}
.pb-73px {
  padding-bottom: 4.5625rem;
}
.pb-74px {
  padding-bottom: 4.625rem;
}
.pb-75px {
  padding-bottom: 4.6875rem;
}
.pb-76px {
  padding-bottom: 4.75rem;
}
.pb-77px {
  padding-bottom: 4.8125rem;
}
.pb-78px {
  padding-bottom: 4.875rem;
}
.pb-79px {
  padding-bottom: 4.9375rem;
}
.pb-80px {
  padding-bottom: 5rem;
}
.pb-81px {
  padding-bottom: 5.0625rem;
}
.pb-82px {
  padding-bottom: 5.125rem;
}
.pb-83px {
  padding-bottom: 5.1875rem;
}
.pb-84px {
  padding-bottom: 5.25rem;
}
.pb-85px {
  padding-bottom: 5.3125rem;
}
.pb-86px {
  padding-bottom: 5.375rem;
}
.pb-87px {
  padding-bottom: 5.4375rem;
}
.pb-88px {
  padding-bottom: 5.5rem;
}
.pb-89px {
  padding-bottom: 5.5625rem;
}
.pb-90px {
  padding-bottom: 5.625rem;
}
.pb-91px {
  padding-bottom: 5.6875rem;
}
.pb-92px {
  padding-bottom: 5.75rem;
}
.pb-93px {
  padding-bottom: 5.8125rem;
}
.pb-94px {
  padding-bottom: 5.875rem;
}
.pb-95px {
  padding-bottom: 5.9375rem;
}
.pb-96px {
  padding-bottom: 6rem;
}
.pb-97px {
  padding-bottom: 6.0625rem;
}
.pb-98px {
  padding-bottom: 6.125rem;
}
.pb-99px {
  padding-bottom: 6.1875rem;
}
.pb-100px {
  padding-bottom: 6.25rem;
}
